html {
  font-size: 18px;
}

body {
  margin: 0;
  font-family: 'Palanquin', 'Antonio', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

textarea {
  box-sizing: border-box;
  width: 100%;
  font-family: 'Palanquin', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 0px;
  min-height: 10rem;
  color: #000;
  border-color: rgba(0, 0, 0, 0.23);
  margin-bottom: 1.5rem;
}

textarea:-moz-placeholder, /* Firefox 18- */
textarea::-moz-placeholder, /* Firefox 19+ */
textarea:-ms-input-placeholder, /* IE 10+ */
textarea::-webkit-input-placeholder, /* Webkit based */
textarea::placeholder {
  /* Modern browsers */
  color: #0bf;
}

textarea:focus {
  border-color: #00518a;
}
